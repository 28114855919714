import Vue from 'vue'
import Vuex from 'vuex'
import gailan from "./modules/gailan"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    nav: 1
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    gailan
  }
})
