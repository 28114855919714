<!--
 * @Date: 2023-08-08 18:01:15
 * @LastEditors: jiangchao
 * @LastEditTime: 2023-08-14 11:57:52
 * @Description: 文件注释
 * @Author: jiangchao
 * @FilePath: \nuoqu\src\layout\index.vue
-->
<template>
  <div>
    <router-view :key="$route.fullPath" />
    <tabbar v-if="$route.meta.showNav">
    </tabbar>
    
  </div>
</template>

<script>
import {Tabbar} from './components'

export default {
  components: {Tabbar},
};
</script>

<style lang="scss" scoped>
.nav {
  width: 100%;
  height: 130px;
  background: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  li {
    width: 80px;
    margin: 0 30px;
    img {
      width: 100%;
      display: block;
    }
  }
}
</style>
