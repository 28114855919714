


export const resPageDataCheck = (data,action) => {
  // 判断状态是否为1 并且msg为存在
  
  if(data.status == 1 && data.msg.includes('存在')){
    return {
      data:data.data,
      totalcount:data.totalcount
    }
  }

  throw `${action} 接口返回错误 ${data.msg}`

}