/*
 * @Description:
 * @Autor: 张健
 * @Date: 2022-07-09 10:33:16
 * @LastEditors: jiangchao
 * @LastEditTime: 2023-10-16 12:59:13
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import * as common from "@/utils/common";
import "vant/lib/index.css";
import dict from "./components/Dict";
import { getData, getDataTest, getDataApp } from "@/api/index";
import { VueJsonp } from "vue-jsonp";
Vue.use(VueJsonp);

// import VConsole from 'vconsole';
Vue.config.productionTip = false;
Vue.prototype.$common = common;
Vue.prototype.$getData = getData;
Vue.prototype.$getDataTest = getDataTest;
Vue.prototype.$getDataApp = getDataApp;


import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.prototype.$KEY = "GD2BZ-2TM3X-RMV4U-TSAL2-WNS2K-VGFAA";

Vue.use(Vant);
Vue.use(dict);
// Vue.use(MapboxVue, { mapboxgl: Mapbox })

// const vConsole = new VConsole();
// Vue.use(vConsole)
// Vue.prototype.$qqMaps = qmap

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
